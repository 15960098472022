@charset "UTF-8";

$custom-gutter: 30px;
$custom-extern-s: 30px;
$custom-extern-l: 45px;

$custom-gutter-narrow: 10px;

.container-fluid {
  overflow: hidden;
  padding-left: $custom-extern-s;
  padding-right: $custom-extern-s;
  @include media-breakpoint-up(sm) {
    padding-left: $custom-extern-l;
    padding-right: $custom-extern-l;
  }
  max-width: $max-w;
}

.row {
  margin-left: $custom-gutter * -0.5;
  margin-right: $custom-gutter * -0.5;

  & > [class*='col-'] {
    padding-right: $custom-gutter * 0.5;
    padding-left: $custom-gutter * 0.5;
  }

  & > [class*='col-']:first-child {
    padding-left: $custom-gutter * 0.5;
  }

  & > [class*='col-']:last-child {
    padding-right: $custom-gutter * 0.5;
  }
}

.container-fluid.narrow .row {
  margin-left: $custom-gutter-narrow * -0.5;
  margin-right: $custom-gutter-narrow * -0.5;

  & > [class*='col-'] {
    padding-right: $custom-gutter-narrow * 0.5;
    padding-left: $custom-gutter-narrow * 0.5;
  }

  & > [class*='col-']:first-child {
    padding-left: $custom-gutter-narrow * 0.5;
  }

  & > [class*='col-']:last-child {
    padding-right: $custom-gutter-narrow * 0.5;
  }
}

// [class*='col-'] {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 100%;
// }
